<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <div>广告编辑</div>
                </div>

                <div class="admin_main_block_right">
                    <div><el-button icon="el-icon-back" @click="$router.go(-1)">返回</el-button></div>
                </div>
            </div>

            <div class="admin_form_main">
                <el-form  label-width="100px" ref="info" :model="info">


                    <el-form-item label="基地" prop="F_IN_JID_ID">
                        <el-select v-model="info.F_IN_JID_ID" placeholder="请选择基地" @change="selectChange()">
                            <el-option label="请选择基地" :value="0"></el-option>
                            <el-option v-for="(v,k) in list['jid']" :label="v.F_IN_JIDMC" :key="k" :value="v.F_IN_ID"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="营期" prop="F_IN_YINGQ_ID">
                        <el-select v-model="info.F_IN_YINGQ_ID" placeholder="请选择营期" @change="selectChange()">
                            <el-option label="请选择营期" :value="0"></el-option>
                            <el-option v-for="(v,k) in list['yingq']" :label="v.F_IN_NAME" :key="k" :value="v.F_IN_ID"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="营期期限" prop="F_IN_YINGQQX_ID">
                        <el-select v-model="info.F_IN_YINGQQX_ID" placeholder="请选择营期期限" @change="selectChange()">
                            <el-option label="请选择营期期限" :value="0"></el-option>
                            <el-option v-for="(v,k) in list['yingqx']" :label="v.F_VR_QISHU" :key="k" :value="v.F_IN_ID"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="招生老师" prop="F_IN_ZSLS_ID">
                        <el-select v-model="info.F_IN_ZSLS_ID" placeholder="请选择招生老师" @change="selectChange()">
                            <el-option label="请选择招生老师" :value="0"></el-option>
                            <el-option v-for="(v,k) in list['zsls']" :label="v.F_VR_XINGM" :key="k" :value="v.F_IN_ID"></el-option>
                        </el-select>
                    </el-form-item>



                    <el-form-item label="姓名" prop="F_VR_XINGM" :rules="[{required:true,message:'姓名不能为空',trigger: 'blur' }]"><el-input placeholder="请输入姓名" v-model="info.F_VR_XINGM"></el-input></el-form-item>
                    <el-form-item label="性别" prop="F_TI_XINGB">
                        <el-select v-model="info.F_TI_XINGB" placeholder="选择性别">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="男" value="1"></el-option>
                            <el-option label="女" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="学费总额" prop="F_VR_XUEFZE" :rules="[{required:true,message:'学费总额不能为空',trigger: 'blur' }]"><el-input placeholder="请输入学费总额" v-model="info.F_VR_XUEFZE"></el-input></el-form-item>
                    <el-form-item label="已收定金" prop="F_VR_YISDJ" :rules="[{required:true,message:'已收定金不能为空',trigger: 'blur' }]"><el-input placeholder="请输入已收定金" v-model="info.F_VR_YISDJ"></el-input></el-form-item>
                    <el-form-item label="年龄" prop="F_IN_NIANL" :rules="[{required:true,message:'年龄不能为空',trigger: 'blur' }]"><el-input placeholder="请输入年龄" v-model="info.F_IN_NIANL"></el-input></el-form-item>
                    <el-form-item label="定金支付方式" prop="F_TI_XINGB">
                        <el-select v-model="info.F_TI_ZHIFFS" placeholder="选择定金支付方式">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="线上支付" value="1"></el-option>
                            <el-option label="线下支付" value="2"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="报道时间">
                        <el-date-picker
                                v-model="info.F_VR_BAODSJ"
                                type="date"
                                placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>


                    <el-form-item label="定金是否结清" prop="F_TI_DINGJSFJQ">
                        <el-select v-model="info.F_TI_DINGJSFJQ" placeholder="选择定金是否结清">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="未结清" value="0"></el-option>
                            <el-option label="已结清" value="1"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="尾款是否结清" prop="F_TI_WEIKSFJQ">
                        <el-select v-model="info.F_TI_WEIKSFJQ" placeholder="选择尾款是否结清">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="未结清" value="0"></el-option>
                            <el-option label="已结清" value="1"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="尾款" prop="F_VR_WEIK" :rules="[{required:true,message:'尾款不能为空',trigger: 'blur' }]"><el-input placeholder="请输入尾款" v-model="info.F_VR_WEIK"></el-input></el-form-item>

                    <el-form-item label="入营通知书是否发放" prop="F_TI_TONGZSSFFF">
                        <el-select v-model="info.F_TI_TONGZSSFFF" placeholder="选择入营通知书是否发放">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="未发放" value="0"></el-option>
                            <el-option label="已发放" value="1"></el-option>
                        </el-select>
                    </el-form-item>


                     <el-form-item label="入营通知书" prop="thumb"><el-upload class="avatar-uploader" :action="$api.advUpload" :headers="upload_headers" :show-file-list="false" :on-success="handleAvatarSuccess" >
                        <img v-if="info.F_VR_RUYTZS" :src="info.F_VR_RUYTZS" class="avatar-upload">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload></el-form-item>
                     <el-form-item>
                        <el-button type="primary" @click="submitForm('info')">提交</el-button>
                        <!-- <el-button @click="resetForm('info')">重置</el-button> -->
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
      return {
          edit_id:0,
          info:{
          },
          list:[],
          upload_headers:{},
      };
    },
    watch: {},
    computed: {},
    methods: {
        resetForm:function(e){
            this.$refs[e].resetFields();
        },
        submitForm:function(e){
            let _this = this;

            // 验证表单
            this.$refs[e].validate(function(res){
                if(res){
                    //  判断是Add 或者 Edit
                    let url = _this.$api.addTongzs;
                    if(_this.edit_id>0){
                        url = _this.$api.editTongzs+_this.edit_id;
                    }
                    // Http 请求
                    _this.$post(url,_this.info).then(function(res){
                        if(res.code == 200){
                            _this.$message.success("编辑成功");
                            _this.$router.go(-1);
                        }else{
                            _this.$message.error("编辑失败");
                        }
                    });
                }
            });
        },
        get_adv_info:function(){
            let _this = this;
            this.$get(this.$api.editTongzs+this.edit_id).then(function(res){
                _this.info = res.data.info;
                _this.list = res.data;
            })

        },
        get_adv_list:function(){
            let _this = this;
            this.$get(this.$api.addTongzs).then(function(res){
                _this.list = res.data;
                _this.info = res.data.info;
            })

        },
        selectChange:function(){
            this.$forceUpdate();
        },
        handleAvatarSuccess(res) {
            this.info.F_VR_RUYTZS = res.data;
            this.$forceUpdate();
        },
    },
    created() {
        this.upload_headers.Authorization = 'Bearer '+localStorage.getItem('token'); // 要保证取到

        if(!this.$isEmpty(this.$route.params.adv_position_id)){
            this.info.ap_id = this.$route.params.adv_position_id;
        }

        // 判断是否是编辑
        if(!this.$isEmpty(this.$route.params.id)){
            this.edit_id = this.$route.params.id;
        }

        if(this.edit_id>0){
            this.get_adv_info();
        }else{
            this.get_adv_list();
        }
        
    },
    mounted() {}
};
</script>
<style lang="scss" scoped>
.line{text-align: center}
</style>